import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../Context";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdDashboard } from "react-icons/md";
import { FaListUl } from "react-icons/fa6";
import { UserRoutes } from "../../App";
import { IoMdSettings } from "react-icons/io";

const UserPageStructure = () : React.ReactElement => {
    const { location } = useContext(Context);

    return(
        <div className='drawer lg:drawer-open'>
          <input id="navi-drawer" type='checkbox' className='drawer-toggle'/>
          <div className='drawer-content flex flex-col' style={{overflow:"scroll", scrollbarWidth :"none"}}>
            <div className='navbar bg-brandPurple w-full lg:hidden relative flex flex-row p-4'>
                <div className='flex-none w-2/3'>
                  <label htmlFor='navi-drawer' aria-label='open sidebar' className='btn btn-square btn-ghost'>
                    <GiHamburgerMenu className='size-10 text-brandLightGray'/>
                  </label>
                </div>
                <div className='w-1/3'>
                  <img src='/CX-Tailor-Logo-Light.png' alt='CX Tailor logo'/>
                </div>
            </div>
            <UserRoutes/>
          </div>
          <div className='drawer-side' style={{boxShadow :"1px 0px 4px 0.5px gray"}}>
            <label htmlFor='navi-drawer' aria-label='close sidebar' className='max-lg:drawer-overlay'></label>
              <div className="bg-brandPurple h-full p-5 flex flex-col justify-start items-center">
                  <img src='/CX-Tailor-Logo-Light.png' alt='CX Tailor logo' className='w-40'/>
                  <ul className='mt-10'>
                    <li className="mt-3">
                        <Link to="/" onClick={()=>{document.title ="CX Tailor - Dashboard"}} className={`flex flex-row justify-start items-center gap-2 btn border-none rounded-3xl text-lg font-light shadow-none
                          ${(location.pathname === "/")?"bg-brandLightGray":"bg-brandPurple text-brandLightGray hover:bg-brandLightPurple"}
                          `}>
                          <MdDashboard/>Dashboard
                        </Link>
                    </li>
                    <li className="mt-3">
                        <Link to="/sequences" onClick={()=>{document.title ="CX Tailor - Lead Magnets"}} className={`flex flex-row justify-start items-center gap-2 btn border-none rounded-3xl text-lg font-light shadow-none
                          ${(location.pathname === "/sequences")?"bg-brandLightGray":"bg-brandPurple text-brandLightGray hover:bg-brandLightPurple"}
                          `}>
                          <FaListUl/>Lead Magnets
                        </Link>
                    </li>
                    <li className="mt-3">
                        <Link to="/settings" onClick={()=>{document.title ="CX Tailor - Settings"}} className={`flex flex-row justify-start items-center gap-2 btn border-none rounded-3xl text-lg font-light shadow-none
                          ${(location.pathname === "/settings")?"bg-brandLightGray":"bg-brandPurple text-brandLightGray hover:bg-brandLightPurple"}
                          `}>
                          <IoMdSettings/>Settings
                        </Link>
                    </li>
                  </ul>
              </div>
          </div>
        </div>
    );
};

export default UserPageStructure;