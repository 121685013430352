import React, { useState } from "react";
import { FaLink } from "react-icons/fa6";
import { FaRegCopy } from "react-icons/fa";
import { FaCheck } from "react-icons/fa"; 

interface Props {
    funnel_id : string,
    apicall : string,
}

const SharingLink = ({ funnel_id, apicall } : Props) : React.ReactElement => {
    const [copied, setCopied] = useState<boolean>(false);
    
    return(
        <div className="bg-brandLightGray p-5 rounded-xl shadow-md w-full flex flex-col gap-2">
            <p className="flex flex-row justify-start items-center gap-2 text-xl"><FaLink/> Share a link to your funnel</p>
            <div className="join w-full">
                <input type="text" value={`${apicall}/funnel/${funnel_id}`} disabled className="w-3/4 join-item p-2 bg-white rounded-full"/>
                <button className="btn join-item bg-brandPurple text-white w-1/4 font-normal hover:bg-brandLightPurple rounded-full"
                onClick={()=>{
                    navigator.clipboard.writeText(`${apicall}/funnel/${funnel_id}`);
                    setCopied(true);
                    setTimeout(()=>{setCopied(false);}, 5000)
                }}>
                    <FaRegCopy className="size-5"/> Copy link
                </button>
            </div>
            {
                (copied)
                ?<div className="toast transition-all">
                    <div className="alert bg-green text-white">
                        <p className="flex flex-row justify-start items-center gap-5"><FaCheck/> Link copied to clipboard</p>
                    </div>
                </div>
                :null
            }
        </div>
    );
};

export default SharingLink;