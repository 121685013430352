import React, { useContext, useState } from "react";
import { FaPlusCircle } from "react-icons/fa";
import { MdOndemandVideo } from "react-icons/md";
import { MdFormatListBulleted } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { GrLinkNext } from "react-icons/gr";
import { Context, Step } from "../../Context";
import { RiTextSnippet } from "react-icons/ri";

interface Props {
    showStepSelection : boolean,
    setShowStepSelection : Function,
    addNewHover : boolean,
    setAddNewHover : Function,
    endScreenAdded : boolean,
    setEndScreenAdded : Function
}

const emptyVideo : Step = {
    action : "video",
    video : {
        url : "",
        poster : "",
        showTimeLeft : false,
        timeLeftPersentage : 50,
        timeLeftText : "",
        CTAscreen : false,
        title : "",
        description : "",
        ctaText : "",
        videoStyle : {width : "100", borderRadius : "10"},
        textScreenStyle : {textAlign : "left", padding : "0", backgroundColor : ""},
        h1Style : {fontSize : "", color : ""},
        pStyle : {fontSize : "", color : ""},
        ctaStyle : {fontSize : "", color : "", padding :"", borderRadius : "", backgroundColor : ""}
    }
}
const emptyForm : Step = {
    action : "form",
    form : {
        header : "",
        description : "",
        inputFields : undefined,
        emailResults : "",
        ctaText : "",
        textScreenStyle : {textAlign : "left", padding : "0", backgroundColor : "#ffffff"},
        h1Style : {fontSize : "24", color : ""},
        pStyle : {fontSize : "18", color : ""},
        labelStyle : {fontSize : "16", color : ""},
        inputStyle : {with : "50", padding : "0", fontSize :"16", borderRadius : ""},
        ctaStyle : {fontSize : "18", color : "#ffffff", backgroundColor : "#000000", padding : "10", borderRadius : "10"}
    }
}
const emptyTextScreen : Step = {
    action : "textScreen",
    textScreen : {
        h1 : "",
        description : "",
        ctaText : "",
        textScreenStyle : {textAlign : "left", padding : "0", backgroundColor : "#ffffff"},
        h1Style : {fontSize : "24", color : ""},
        pStyle : {fontSize : "18", color : ""},
        ctaStyle : {fontSize : "18", color : "#ffffff", backgroundColor : "#000000", padding : "10", borderRadius : "10"}
    }
}
const emptyEndScreen : Step = {
    action : "end screen",
    thankYouPage : {
        h1 : "",
        description : "",
        ctaText : "",
        ctaUrl : "",
        textScreenStyle : {textAlign : "left", padding : "0", backgroundColor : "#ffffff"},
        h1Style : {fontSize : "24", color : ""},
        pStyle : {fontSize : "18", color : ""},
        ctaStyle : {fontSize : "18", color : "#ffffff", backgroundColor : "#000000", padding : "10", borderRadius : "10"}
    }
}

const StepSelectionModal = ({showStepSelection, setShowStepSelection, addNewHover, setAddNewHover, endScreenAdded, setEndScreenAdded}:Props) : React.ReactElement => {
    const {steps, setSteps} = useContext(Context);
    const [videoHover, setVideoHover] = useState<boolean>(false);
    const [formHover, setFormHover] = useState<boolean>(false);
    const [endHover, setEndHover] = useState<boolean>(false);
    const [textScreenHover, setTextScreenHover] = useState<boolean>(false);


    return(
        <div className="flex flex-col justify-center items-center">
            <p className={`${(addNewHover && !endScreenAdded)?"":"invisible"} text-xl transition`}>Add new step</p>
            <FaPlusCircle className={`size-10 ${(!endScreenAdded)?"text-green hover:scale-105":"text-neutral-700"} cursor-pointer transition`} 
            onMouseEnter={()=>{setAddNewHover(true)}} onMouseLeave={()=>{setAddNewHover(false)}} onClick={()=>{if(!endScreenAdded){setShowStepSelection(true)}}}/>

            <input type="checkbox" checked={showStepSelection} id="stepSelection" className="modal-toggle" readOnly/>
            <div className="modal" role="dialog">
                <div className="modal-box flex flex-col justify-start items-start gap-3 relative">
                    <p className="text-2xl">Add a new step</p>
                    <p className="text-md">Select a new step to add to your funnel.</p>
                    <div className="
                    p-2 flex flex-row justify-start items-center gap-2 
                    cursor-pointer transition-all
                    border-[1px] border-brandPurple rounded-xl w-full 
                    hover:bg-brandPurple hover:text-white text-brandPurple
                    "
                    onMouseEnter={()=>{setVideoHover(true)}} onMouseLeave={()=>{setVideoHover(false)}}
                    onClick={()=>{setShowStepSelection(false); if(steps){
                        setSteps([...steps, emptyVideo])
                        }else{
                            setSteps([emptyVideo])
                        }}}>
                        <div>
                            <MdOndemandVideo className="size-14"/>
                        </div>
                        <div>
                            <p className="text-lg">Video Step</p>
                            <p className={`text-xs ${(videoHover)?"":"text-white"} transition-all`}>Add engaging video steps to capture your visitors focus.</p>
                        </div>
                    </div>

                    <div className="
                    p-2 flex flex-row justify-start items-center gap-2 
                    cursor-pointer transition-all
                    border-[1px] border-brandPurple rounded-xl w-full
                    hover:bg-brandPurple hover:text-white text-brandPurple
                    "
                    onMouseEnter={()=>{setFormHover(true)}} onMouseLeave={()=>{setFormHover(false)}}
                    onClick={()=>{setShowStepSelection(false); if(steps){
                        setSteps([...steps, emptyForm])
                        }else{
                            setSteps([emptyForm])
                        }}}>
                        <div>
                            <MdFormatListBulleted className="size-14"/>
                        </div>
                        <div>
                            <p className="text-lg">Form Step</p>
                            <p className={`text-xs ${(formHover)?"":"text-white"} transition-all`}>Collect leads & contact details.</p>
                        </div>
                    </div>
                    <div className="
                    p-2 flex flex-row justify-start items-center gap-2 
                    cursor-pointer transition-all
                    border-[1px] border-brandPurple rounded-xl w-full
                    hover:bg-brandPurple hover:text-white text-brandPurple
                    "
                    onMouseEnter={()=>{setTextScreenHover(true)}} onMouseLeave={()=>{setTextScreenHover(false)}}
                    onClick={()=>{setShowStepSelection(false); if(steps){
                        setSteps([...steps, emptyTextScreen])
                        }else{
                            setSteps([emptyTextScreen])
                        }}}>
                        <div>
                            <RiTextSnippet className="size-14"/>
                        </div>
                        <div>
                            <p className="text-lg">Text Screen</p>
                            <p className={`text-xs ${(textScreenHover)?"":"text-white"} transition-all`}>Text screen with simple CTA with no URL redirects</p>
                        </div>
                    </div>    
                    <div className="
                    p-2 flex flex-row justify-start items-center gap-2 
                    cursor-pointer transition-all
                    border-[1px] border-brandPurple rounded-xl w-full
                    hover:bg-brandPurple hover:text-white text-brandPurple
                    "
                    onMouseEnter={()=>{setEndHover(true)}} onMouseLeave={()=>{setEndHover(false)}}
                    onClick={()=>{setEndScreenAdded(true); setShowStepSelection(false); if(steps){
                        setSteps([...steps, emptyEndScreen])
                        }else{
                            setSteps([emptyEndScreen])
                        }}}>
                        <div>
                            <GrLinkNext className="size-14"/>
                        </div>
                        <div>
                            <p className="text-lg">End Screen</p>
                            <p className={`text-xs ${(endHover)?"":"text-white"} transition-all`}>Drive follow up steps after your funnel.</p>
                        </div>
                    </div>
                    <RxCross2 className="absolute top-2 right-2 size-8 cursor-pointer" onClick={()=>{setShowStepSelection(false);}}/>
                </div>
            </div>
        </div>
    );
};

export default StepSelectionModal;