import React, { useContext, useEffect, useRef, useState } from "react";
import { FormInterface, Step, TextScreenInterface, ThankYouInterface, VideoInterface } from "../../components/Context";
import { PublicContext, SelectedFunnel } from "../../components/PublicContext";

interface VideoPrevProps {
    video : VideoInterface,
    setCurrentStep : Function,
    currentStep : number,
    sendStepData : Function,
    selectedFunnel : SelectedFunnel
}

const VideoPreview = ({video, setCurrentStep, currentStep, sendStepData, selectedFunnel}: VideoPrevProps) : React.ReactElement => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [playRecorded, setPlayRecorded] = useState<boolean>(false);
    const videoPrev : VideoInterface = (
        (video.textScreenStyle && video.h1Style && video.pStyle && video.ctaStyle)
        ? video
        : {
            url : video.url,
            poster : video.poster,
            showTimeLeft : video.showTimeLeft,
            timeLeftPersentage : video.timeLeftPersentage,
            timeLeftText : video.timeLeftText,
            CTAscreen : (video.CTAscreen) ? video.CTAscreen:(video.title && video.ctaText)?true:false,
            title : video.title,
            description : video.description,
            ctaText : video.ctaText,
            videoStyle : {width : "100", borderRadius : "10"},
            textScreenStyle : {textAlign : "left", padding : "0", backgroundColor : ""},
            h1Style : {fontSize : "", color : ""},
            pStyle : {fontSize : "", color : ""},
            ctaStyle : {fontSize : "", color : "", padding :"", borderRadius : "", backgroundColor : ""}
        }
    ) 
    const [preview, setPreview] = useState({CTAscreen : false, timeLeft : false , timeText : 0});

    const DisplayTimeLeft = () => {
        if(videoRef.current?.currentTime){
            if(videoRef.current.duration){
                if(video.timeLeftPersentage){
                    if((videoRef.current.currentTime / videoRef.current.duration) * 100 >= video.timeLeftPersentage){
                        setPreview({...preview, timeLeft : true, timeText : Number((videoRef.current.duration - videoRef.current.currentTime).toFixed(0))});
                    }else{
                        setPreview({...preview, timeLeft : false, timeText : 0});
                    }
                }
            }
        }
    }

    return(
        <div>
            {
                (preview.CTAscreen)
                ?<div className={`text-${videoPrev.textScreenStyle.textAlign}`} style={{
                    padding : `${videoPrev.textScreenStyle.padding}px`, 
                    backgroundColor :`${videoPrev.textScreenStyle.backgroundColor}`
                }}>
                    {(videoPrev.title)?<p style={{fontSize : `${videoPrev.h1Style.fontSize}px`, color: `${videoPrev.h1Style.color}`}}>
                                            {videoPrev.title}
                                        </p>:null}
                    {(videoPrev.description)?<p style={{fontSize : `${videoPrev.pStyle.fontSize}px`, color: `${videoPrev.pStyle.color}`}}>
                                                {videoPrev.description}
                                                </p>:null}
                    {(videoPrev.ctaText)
                    ?<button style={{
                        fontSize : `${videoPrev.ctaStyle.fontSize}px`, 
                        color: `${videoPrev.ctaStyle.color}`, backgroundColor :`${videoPrev.ctaStyle.backgroundColor}`,
                        padding:`${videoPrev.ctaStyle.padding}px`, borderRadius : `${videoPrev.ctaStyle.borderRadius}px`
                    }}
                    onClick={()=>{setPreview({...preview, CTAscreen:false})}}>
                        {videoPrev.ctaText}
                    </button>
                    :null}
                </div>
                :<div>
                    <video ref={videoRef} src={videoPrev.url} poster={videoPrev.poster} onPlay={()=>{if(!playRecorded){
                        sendStepData(selectedFunnel.workspace_id, selectedFunnel.ABtest_id, selectedFunnel.AB_variant, selectedFunnel.form_id, currentStep, "start");
                        setPlayRecorded(true);
                    }}} 
                    onTimeUpdate={DisplayTimeLeft} controls onEnded={()=>{setCurrentStep(currentStep)}}
                    style={{width : `${videoPrev.videoStyle.width}%`, borderRadius : `${videoPrev.videoStyle.borderRadius}px`}}>
                    </video>
                    {(preview.timeLeft && videoPrev.showTimeLeft)
                    ?<p className="text-right">{`${videoPrev.timeLeftText} : ${preview.timeText}s`}</p>
                    :null
                    }
                </div>
            }
        </div>
    );
}

interface FormPrevProps {
    form : FormInterface,
    setCurrentStep : Function,
    currentStep : number,
    selectedFunnel : SelectedFunnel,
    submitNewLead : Function,
    stepData : any[],
}

const FormPreview = ({form, setCurrentStep, currentStep, submitNewLead, selectedFunnel, stepData} : FormPrevProps) : React.ReactElement => {
    const formPrev : FormInterface = (form.textScreenStyle && form.h1Style && form.pStyle && form.labelStyle && form.inputStyle && form.ctaStyle) 
    ? form 
    : {
        header : "",
        description : "",
        firstNameLabel : "",
        lastNameLabel : "",
        companyLabel : "",
        emailLabel : "",
        emailResults : "",
        ctaText : "",
        textScreenStyle : {textAlign : "left", padding : "0", backgroundColor : "#ffffff"},
        h1Style : {fontSize : "24", color : ""},
        pStyle : {fontSize : "18", color : ""},
        labelStyle : {fontSize : "16", color : ""},
        inputStyle : {with : "50", padding : "0", fontSize :"16", borderRadius : ""},
        ctaStyle : {fontSize : "18", color : "#ffffff", backgroundColor : "", padding : "10", borderRadius : "10"}
    }

    const handleFormSubmission = (event : React.SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        let formFields : any = {};

        form.inputFields.forEach((input : any, idx : number)=>{
            if(event.currentTarget.elements[input.label]){
                const formElement = event.currentTarget.elements.namedItem(input.label) as HTMLInputElement
                formFields[input.name] = formElement!.value;
            }
        });

        submitNewLead(selectedFunnel, form.emailResults, formFields, stepData);
        setCurrentStep(currentStep);
    } 

    return(
        <div className={`text-${formPrev.textScreenStyle.textAlign}`} style={{padding : `${formPrev.textScreenStyle.padding}px`, 
            backgroundColor : `${formPrev.textScreenStyle.backgroundColor}`}}>
            {(formPrev.header)?
            <p style={{fontSize : `${formPrev.h1Style.fontSize}px`, color : `${formPrev.h1Style.color}`}}>
                {formPrev.header}
            </p>
            :null}
            {(formPrev.description)?
            <p style={{fontSize : `${formPrev.pStyle.fontSize}px`, color : `${formPrev.pStyle.color}`}} className="mt-5">
                {formPrev.description}
            </p>
            :null}
            <form className="mt-5" onSubmit={handleFormSubmission}>
                {
                (form.inputFields && form.inputFields.length > 0)
                ?<>{form.inputFields.map((input : any, idx : number) => {
                    return<div key={idx}>
                            <label htmlFor={input.name} style={{fontSize : `${form.labelStyle.fontSize}px`, color : `${form.labelStyle.color}`}}>
                                {input.label}
                            </label><br/>
                            <input type={input.type} name={(input.name === "other")?`${input.name}${idx}`:input.name} id={input.label} className="border-[1px]" required 
                            style={{width :`${form.inputStyle.width}%`, padding : `${form.inputStyle.padding}px`,
                            fontSize : `${form.inputStyle.fontSize}px`, borderRadius: `${form.inputStyle.borderRadius}px`}}/>
                        </div>
                })}</>
                :<>
                <label htmlFor="firstName" style={{fontSize : `${formPrev.labelStyle.fontSize}px`, color : `${formPrev.labelStyle.color}`}}>
                {(formPrev.firstNameLabel)?formPrev.firstNameLabel:"Fist name"}
                </label><br/>
                <input type="text" name="firstName" className="border-[1px]" required
                style={{width :`${formPrev.inputStyle.width}%`, padding : `${formPrev.inputStyle.padding}px`,
                fontSize : `${formPrev.inputStyle.fontSize}px`, borderRadius: `${formPrev.inputStyle.borderRadius}px`}}/><br/>
                <label htmlFor="lastName" style={{fontSize : `${formPrev.labelStyle.fontSize}px`, color : `${formPrev.labelStyle.color}`}}>
                    {(formPrev.lastNameLabel)?formPrev.lastNameLabel:"Last name"}
                </label><br/>
                <input type="text" name="lastName" className="border-[1px]" required
                style={{width :`${formPrev.inputStyle.width}%`, padding : `${formPrev.inputStyle.padding}px`,
                fontSize : `${formPrev.inputStyle.fontSize}px`, borderRadius: `${formPrev.inputStyle.borderRadius}px`}}/><br/>
                <label htmlFor="email" style={{fontSize : `${formPrev.labelStyle.fontSize}px`, color : `${formPrev.labelStyle.color}`}}>
                    {(formPrev.emailLabel)?formPrev.emailLabel:"Email"}
                </label><br/>
                <input type="text" name="email" className="border-[1px]" required
                style={{width :`${formPrev.inputStyle.width}%`, padding : `${formPrev.inputStyle.padding}px`,
                fontSize : `${formPrev.inputStyle.fontSize}px`, borderRadius: `${formPrev.inputStyle.borderRadius}px`}}/><br/>
                <label htmlFor="company" style={{fontSize : `${formPrev.labelStyle.fontSize}px`, color : `${formPrev.labelStyle.color}`}}>
                    {(formPrev.companyLabel)?formPrev.companyLabel:"Company"}
                    </label><br/>
                <input type="text" name="company" className="border-[1px]" required
                style={{width :`${formPrev.inputStyle.width}%`, padding : `${formPrev.inputStyle.padding}px`,
                fontSize : `${formPrev.inputStyle.fontSize}px`, borderRadius: `${formPrev.inputStyle.borderRadius}px`}}/><br/>
                </>
                }
                <button className="mt-5" type="submit"
                style={{fontSize : `${formPrev.ctaStyle.fontSize}px`, padding :  `${formPrev.ctaStyle.padding}px`, borderRadius : `${formPrev.ctaStyle.borderRadius}px`,
                color :  `${formPrev.ctaStyle.color}`, backgroundColor :  `${formPrev.ctaStyle.backgroundColor}`}}>
                    {(formPrev.ctaText)?formPrev.ctaText:"Submit form"}
                </button>
            </form>
        </div>
    );
};

interface TextPrevProps {
    textScreen : TextScreenInterface,
    setCurrentStep : Function,
    currentStep : number,
}

const TextPreview = ({textScreen, setCurrentStep, currentStep} : TextPrevProps) : React.ReactElement => {
    const textPrev : TextScreenInterface = (textScreen.textScreenStyle && textScreen.h1Style && textScreen.pStyle && textScreen.ctaStyle)
    ?textScreen 
    :{
        h1 : textScreen.h1,
        description : textScreen.description,
        ctaText : textScreen.ctaText,
        textScreenStyle : {textAlign : "left", padding : "0", backgroundColor : "#ffffff"},
        h1Style : {fontSize : "24", color : ""},
        pStyle : {fontSize : "18", color : ""},
        ctaStyle : {fontSize : "18", color : "#ffffff", backgroundColor : "", padding : "10", borderRadius : "10"}
    }

    return(
        <div className={`text-${textPrev.textScreenStyle.textAlign}`} style={{padding : `${textPrev.textScreenStyle.padding}px`, 
        backgroundColor : `${textPrev.textScreenStyle.backgroundColor}`}}>
            {(textPrev.h1)?
            <p style={{fontSize : `${textPrev.h1Style.fontSize}px`, color : `${textPrev.h1Style.color}`}} className="">
                {textPrev.h1}
            </p>
            :null}
            {(textPrev.description)?
            <p style={{fontSize : `${textPrev.pStyle.fontSize}px`, color : `${textPrev.pStyle.color}`}} className="mt-5">
                {textPrev.description}
            </p>
            :null}
            <button onClick={()=>{setCurrentStep(currentStep);}} className="mt-5"
            style={{fontSize : `${textPrev.ctaStyle.fontSize}px`, padding :  `${textPrev.ctaStyle.padding}px`, borderRadius : `${textPrev.ctaStyle.borderRadius}px`,
            color :  `${textPrev.ctaStyle.color}`, backgroundColor :  `${textPrev.ctaStyle.backgroundColor}`}}>
                {(textPrev.ctaText)?textPrev.ctaText:"Continue"}
            </button>
        </div>
    );
}

interface EndPrevProps {
    end : ThankYouInterface,
    currentStep : number,
    sendStepData : Function,
    selectedFunnel : SelectedFunnel,
}

const EndPreview = ({end, sendStepData, selectedFunnel, currentStep} : EndPrevProps) : React.ReactElement => {
    const endPrev : ThankYouInterface = (end.textScreenStyle && end.h1Style && end.pStyle && end.ctaStyle)
    ?end 
    :{
        h1 : end.h1,
        description : end.description,
        ctaText : end.ctaText,
        ctaUrl : end.ctaUrl,
        textScreenStyle : {textAlign : "left", padding : "0", backgroundColor : "#ffffff"},
        h1Style : {fontSize : "24", color : ""},
        pStyle : {fontSize : "18", color : ""},
        ctaStyle : {fontSize : "18", color : "#ffffff", backgroundColor : "", padding : "10", borderRadius : "10"}
    }

    const endRedirect = () => {
        sendStepData(selectedFunnel.workspace_id, selectedFunnel.ABtest_id, selectedFunnel.AB_variant, selectedFunnel.form_id, currentStep, "finish");
        window.location.href = end.ctaUrl;
    }

    return(
        <div className={`text-${endPrev.textScreenStyle.textAlign}`} style={{padding : `${endPrev.textScreenStyle.padding}px`, 
                    backgroundColor : `${endPrev.textScreenStyle.backgroundColor}`}}>
            {(endPrev.h1)?
            <p style={{fontSize : `${endPrev.h1Style.fontSize}px`, color : `${endPrev.h1Style.color}`}}>
                {endPrev.h1}
            </p>
            :null}
            {(endPrev.description)?
            <p style={{fontSize : `${endPrev.pStyle.fontSize}px`, color : `${endPrev.pStyle.color}`}} className="mt-5">
                {endPrev.description}
            </p>
            :null}
            <button className="btn mt-5 font-normal" onClick={endRedirect}
            style={{fontSize : `${endPrev.ctaStyle.fontSize}px`, padding :  `${endPrev.ctaStyle.padding}px`, borderRadius : `${endPrev.ctaStyle.borderRadius}px`,
            color :  `${endPrev.ctaStyle.color}`, backgroundColor :  `${endPrev.ctaStyle.backgroundColor}`}}>
                {(endPrev.ctaText)?endPrev.ctaText:"Continue"}
            </button>
        </div>
    );
}

const Demo = () : React.ReactElement => {
    const {selectedFunnel, sendStepData, submitNewLead} = useContext(PublicContext);
    const [stepData, setStepData] = useState<any>([]);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [viewAdded, setViewAdded] = useState<boolean>(false);
    const [bgColor, setBgColor] = useState<string>("white");

    //handles changin the funnel step & sending data from both finish of current step and the start of next step
    const MoveForward = (step : number) => {
        sendStepData(selectedFunnel.workspace_id, selectedFunnel.ABtest_id, selectedFunnel.AB_variant, selectedFunnel.form_id, step, "finish");
        if(selectedFunnel.steps[currentStep].video){
            setStepData([...stepData, {engagement : "video", log : new Date().toLocaleString("fi-FI")}]);
        }
        if(selectedFunnel.steps[currentStep].form){
            setStepData([...stepData, {engagement : "form submission", log : new Date().toLocaleString("fi-FI")}]);
        }
        if(selectedFunnel.steps[currentStep].textScreen){
            setStepData([...stepData, {engagement : "text screen", log : new Date().toLocaleString("fi-FI")}]);
        }
        setCurrentStep(step + 1);
        if(!selectedFunnel.steps[currentStep + 1].video){
            sendStepData(selectedFunnel.workspace_id, selectedFunnel.ABtest_id, selectedFunnel.AB_variant, selectedFunnel.form_id, step + 1, "start");
        }
    }

    //handles sending the initial view data
    useEffect(()=>{
        if(!viewAdded && selectedFunnel){
            sendStepData(selectedFunnel.workspace_id, selectedFunnel.ABtest_id, selectedFunnel.AB_variant, selectedFunnel.form_id, 0, "view");
            setViewAdded(true);
            if(!selectedFunnel.steps[0].video){
                sendStepData(selectedFunnel.workspace_id, selectedFunnel.ABtest_id, selectedFunnel.AB_variant, selectedFunnel.form_id, 0, "start");
            }
        }
    }, [viewAdded, selectedFunnel, sendStepData])

    return(
        <div className="w-dvw h-dvh flex justify-center items-center overflow-hidden relative"
        style={{ backgroundColor : `${bgColor}`}}>
            {
                (selectedFunnel && selectedFunnel.steps.length > 0)
                ?<div className="absolute top-0 w-screen h-4">
                    <div className={`h-full bg-brandPurple transition-all`} 
                    style={{width : `${((currentStep + 1) / (selectedFunnel.steps.length) * 100).toFixed(0)}%`}}></div>
                </div>
                :null
            }
            {
                (selectedFunnel)
                ?<div className="max-lg:w-3/5 md:w-4/6 max-sm:w-[80%]">
                    {
                        (selectedFunnel.steps && selectedFunnel.steps.length > 0)
                        ?<div>
                            {selectedFunnel.steps.map((step : Step, idx : number) => {
                                if(step.action === "video" && idx === currentStep){
                                    if(step.video?.textScreenStyle && bgColor !== step.video.textScreenStyle.backgroundColor){
                                        setBgColor(step.video.textScreenStyle.backgroundColor);
                                    }
                                    return <VideoPreview video={step.video!} sendStepData={sendStepData} selectedFunnel={selectedFunnel}
                                    setCurrentStep={MoveForward} currentStep={currentStep}/>
                                }
                                if(step.action === "form" && idx === currentStep){
                                    if(step.form?.textScreenStyle && bgColor !== step.form.textScreenStyle.backgroundColor){
                                        setBgColor(step.form.textScreenStyle.backgroundColor);
                                    }
                                    return <FormPreview form={step.form!} selectedFunnel={selectedFunnel} submitNewLead={submitNewLead} stepData={stepData}
                                    setCurrentStep={MoveForward} currentStep={currentStep}/>
                                }
                                if(step.action === "textScreen" && idx === currentStep){
                                    if(step.textScreen?.textScreenStyle && bgColor !== step.textScreen.textScreenStyle.backgroundColor){
                                        setBgColor(step.textScreen.textScreenStyle.backgroundColor);
                                    }
                                    return <TextPreview textScreen={step.textScreen!}
                                    setCurrentStep={MoveForward} currentStep={currentStep}/>
                                }
                                if(step.action === "end screen" && idx === currentStep){
                                    if(step.thankYouPage?.textScreenStyle && bgColor !== step.thankYouPage.textScreenStyle.backgroundColor){
                                        setBgColor(step.thankYouPage.textScreenStyle.backgroundColor);
                                    }
                                    return <EndPreview end={step.thankYouPage!} currentStep={currentStep} sendStepData={sendStepData} selectedFunnel={selectedFunnel}/>
                                }
                                if(currentStep >= selectedFunnel.steps.length){
                                    setCurrentStep(0);
                                }
                                return null;
                            })}
                        </div>
                        :<div className="skeleton h-96 w-full"></div>
                    }
                </div>
                :<p>Loading...</p>
            }
            {
                (selectedFunnel && selectedFunnel.showBranding)
                ? <a className="absolute bottom-2 right-2 
                w-fit flex flex-row justify-center items-center shadow-xl 
                p-2 pr-4 pl-4 rounded-xl cursor-pointer bg-brandPurple
                hover:bg-brandLightPurple transition text-white text-xs" 
                    href="https://cxtailor.com/" target="_blank">
                        <p>Built with</p>
                        <img src="/CX-Tailor-Logo-Light.png" alt='CX Tailor logo' className="h-4 pr-2 pl-2"/>
                  </a>
                :null
            }
        </div>
    );
};

export default Demo;